import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ClientsApi } from "@unity/components";
import FilterTable from "../../common/FilterTable";
import CompaniesHouseTabs from "./CompaniesHouseTabs";

export default function CompaniesHouseForm({ company_number, context }) {
  const [data, setData] = useState(false);
  const [officers, setOfficers] = useState([]);
  const [value, setValue] = useState(0);

  const getData = async () => {
    const res = await ClientsApi.companiesHouseGet(company_number);
    setData(res);
    const res2 = await ClientsApi.companiesHouseGetOfficers(company_number);
    setOfficers(res2);
  };

  console.log(officers);

  const columns = [
    { name: "name", label: "Name" },
    { name: "officer_role", label: "Role" },
    {
      name: "",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (officers.items[dataIndex].resigned_on) {
            return (
              <b style={{ color: "red" }}>
                {officers.items[dataIndex].resigned_on}
              </b>
            );
          } else {
            return <b style={{ color: "green" }}>Active</b>;
          }
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    search: false,
    print: false,
    filter: false,
    download: false,
    columns: false,
  };

  const img = require("../../assets/companies-house-banner.png");

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <div className="block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <img src={img} style={{ width: "15%" }} />
              </div>
            </div>
          </div>
        </div>

        <div className="block">
          <div className="container-fluid">
            <div className="row" style={{ marginBottom: 15 }}>
              <div className="col-lg-3">
                <TextField
                  fullWidth
                  label="Company Name"
                  value={data.company_name}
                />
              </div>
              <div className="col-lg-3">
                <TextField fullWidth label="Company Type" value={data.type} />
              </div>
              <div className="col-lg-3">
                <TextField
                  fullWidth
                  label="Company Status"
                  value={data.company_status}
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  fullWidth
                  label="Company Creation Date"
                  value={data.date_of_creation}
                />
              </div>
            </div>

            <div className="row" style={{ marginBottom: 15 }}>
              <div className="col-lg-3">
                <TextField
                  fullWidth
                  label="Has Charges"
                  value={data.has_charges}
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  fullWidth
                  label="Has Insolvency History"
                  value={data.has_insolvency_history}
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  fullWidth
                  label="Has Been Liquidated"
                  value={data.has_been_liquidated}
                />
              </div>
              <div className="col-lg-3">
                <TextField fullWidth label="Can File" value={data.can_file} />
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <CompaniesHouseTabs
            context={context}
            value={value}
            setValue={setValue}
          />
        </div>

        {value === 0 && data.registered_office_address && (
          <div className="block">
            <div className="container-fluid">
              <div className="row" style={{ marginBottom: 15 }}>
                <div className="col-lg-12" style={{ marginBottom: 15 }}>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    value={data.registered_office_address.address_line_1}
                  />
                </div>
                <div className="col-lg-12" style={{ marginBottom: 15 }}>
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    value={data.registered_office_address.address_line_1}
                  />
                </div>
                <div className="col-lg-4">
                  <TextField
                    fullWidth
                    label="Postcode"
                    value={data.registered_office_address.postal_code}
                  />
                </div>
                <div className="col-lg-4">
                  <TextField
                    fullWidth
                    label="Region"
                    value={data.registered_office_address.region}
                  />
                </div>
                <div className="col-lg-4">
                  <TextField
                    fullWidth
                    label="Locality"
                    value={data.registered_office_address.locality}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {value === 1 && (
          <div style={{ marginTop: 20 }}>
            <FilterTable
              columns={columns}
              options={options}
              data={officers.items}
            />
          </div>
        )}
      </>
    );
  } else {
    return <p>Loading</p>;
  }
}
