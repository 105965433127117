import React, { useEffect, useState } from "react";
import ApiLoader from "../../common/ApiLoader";
import { ClientsApi, TargetsApi } from "@unity/components";
//import ClientReports from "../../common/ClientReports";
//import PageHeader from "../../common/PageHeader";
//import ClientForm from "../../coonents/modules/clients/ClientForm";
import { useHistory, useLocation } from "react-router-dom";
//import { saveTargets } from "../../api/targets";

import { RenderPageTemplate } from "../../mapping";
import TargetStandardForm from "../../forms/TargetStandardForm";

import {
  AppHeaderComponent,
  CampaignApi,
  PersonnelApi,
} from "@unity/components";

export default function TargetCreate(props) {
  const [form, setForm] = useState({ client_site: [] });
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [errors, setErrors] = useState(false);
  const [docs, setDocs] = useState([]);

  //Setting the phone and email pref to false so they will be controlled
  const [data, setData] = useState({
    phone_pref: false,
    email_pref: false,
    misc: [],
  });

  let history = useHistory();
  let stateType =
    props.route.location.state == undefined
      ? "edit"
      : props.route.location.state.type;

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const getData = async () => {
    if (stateType === "edit") {
      const res = await TargetsApi.getSingleTarget(props.route.match.params.id);
      setData(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSave = async () => {
    setLoading({ status: true, data: "Loading please wait..." });
    if (stateType === "edit") {
      const editSave = await TargetsApi.updateTarget(data, data.id);
      setLoading({ status: true, data: "Save Success please wait..." });
      setTimeout(() => {
        history.push("/targets/index");
      }, 4000);
    } else {
      const save = await TargetsApi.saveTarget(data);

      setLoading({ status: true, data: "Save Success please wait..." });
      setTimeout(() => {
        history.push("/targets/index");
      }, 4000);
    }
  };

  const handleMiscChange = (name, value, key) => {
    const newData = { ...data };
    newData["misc"][key][name] = value;
    setData(newData);
  };

  const handleMiscAdd = () => {
    const newData = { ...data };
    newData.misc.push({});
    setData(newData);
  };

  const handleMiscDelete = (key) => {
    const newData = { ...data };
    newData.misc.splice(key, 1);
    setData(newData);
  };

  if (data) {
    return (
      <>
        <ApiLoader status={loading.status} data={loading.data} />

        <AppHeaderComponent
          subpage="create"
          saveBtn={handleSave}
          theme={props.context.theme}
          context={props.context}
          name={"targets"}
        />
        <div style={{ paddingTop: "120px" }}>
          <TargetStandardForm
            data={data}
            setData={setData}
            handleChange={handleChange}
            type={stateType}
            //Misc Table
            handleMiscAdd={handleMiscAdd}
            handleMiscDelete={handleMiscDelete}
            handleMiscChange={handleMiscChange}
          />
        </div>
      </>
    );
  }
}
