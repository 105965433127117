import React from 'react';
import { ClientsApi } from "@unity/components";

import CompaniesHouseForm from './CompaniesHouseForm';
import CompaniesHouseFormSearch from './CompaniesHouseFormSearch';

export default function CompaniesHouseModule(props) {

    const handleAttach = async (value, setOpen) => {
        console.log(value)
        props.data['company_number'] = value;
        await ClientsApi.updateClient(props.props.data);
        const clientsData = await ClientsApi.getClients(props.props.reference);
        props.setData(clientsData.data);
        setOpen({ state: false, number: "" });
    }

    return (
        <div>
            {!props.data.company_number ?
                <CompaniesHouseFormSearch context={props.context} handleAttach={handleAttach} />
                :
                <CompaniesHouseForm context={props.context} company_number={props.data.company_number} />
            }
        </div>
    )
}