import React from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { AppButtonComponent } from "@unity/components";

import { TextField, Select, MenuItem, makeStyles } from "@material-ui/core";

export default function TargetMiscTable({
  data,
  type,
  handleMiscChange,
  handleMiscAdd,
  handleMiscDelete,
}) {
  const useStyles = makeStyles({
    table: {
      minWidth: 70,
    },
  });

  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Custom Field</TableCell>
              <TableCell>Custom Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.misc &&
              data.misc.map((records, key) => (
                <TableRow key={key}>
                  <TableCell>
                    <TextField
                      disabled={type === "view"}
                      name="custom_name"
                      value={records.custom_name}
                      onChange={(e) =>
                        handleMiscChange(e.target.name, e.target.value, key)
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      disabled={type === "view"}
                      name="custom_value"
                      value={records.custom_value}
                      onChange={(e) =>
                        handleMiscChange(e.target.name, e.target.value, key)
                      }
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {type != "view" && (
          <AppButtonComponent
            disabled={type === "view"}
            btnIcon="add"
            text="add new"
            style={{ background: "blue", color: "white" }}
            onClick={() => handleMiscAdd()}
          />
        )}
        {type != "view" && (
          <AppButtonComponent
            disable={type === "view"}
            btnIcon="delete"
            text="Delete"
            style={{ background: "blue", color: "white" }}
            onClick={() => handleMiscDelete()}
          />
        )}
      </TableContainer>
    </>
  );
}
