//React Imports
import React, { useEffect, useState } from "react";

//3rd Party Imports
import { Select, InputLabel, MenuItem } from "@material-ui/core";

//Own Comp Imports
import { DynamicListApi, TargetsApi } from "@unity/components";
import DataTable from "../../common/DataTable";

export default function InListDisplay() {
  const [listData, setListData] = useState(false);
  const [targetData, setTargetData] = useState(false);
  const [rowsTotal, setRowsTotal] = useState(0);

  const getListData = async () => {
    const res = await DynamicListApi.getDynamicListIndex();
    setListData(res.data);
  };

  const handleSelect = async (list) => {
    const newObj = {
      criteria: list.criteria,
      search: "",
    };
    const res = await TargetsApi.filterSetPaginateTargets(newObj);

    setTargetData(res.data);

    console.log(res.data);

    setRowsTotal(res.data.total);
  };

  const handlePageChange = async (page) => {};

  const options = {
    pageSize: 15,
    rowCount: rowsTotal,
    rowsPerPageOptions: [15],
    handlePageChange: handlePageChange,
    autoHeight: false,
    height: 350,
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "company_name",
      headerName: "Company Name",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      flex: 1,
      minWidth: 300,
    },
    { field: "contact_email", headerName: "Email", flex: 1, minWidth: 300 },
    { field: "contact_phone", headerName: "Phone", flex: 1, minWidth: 300 },
  ];

  useEffect(() => {
    getListData();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="form-row">
          <div className="from-group col-lg-12">
            <h4>Targets In List</h4>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-12">
            <InputLabel>Select Which List</InputLabel>
            <Select
              fullWidth
              onChange={(e) => {
                handleSelect(e.target.value);
              }}
            >
              {listData &&
                listData.map((list, key) => (
                  <MenuItem key={key} value={list}>
                    {list.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
        {/* {targetData && ( */}
        <DataTable
          columns={columns}
          rows={targetData.data}
          pagination={true}
          options={options}
        />
        {/* )} */}
      </div>
    </>
  );
}
