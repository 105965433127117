import React, { useEffect, useState } from "react";
import { DynamicListApi, ClientsApi } from "@unity/components";
import SmallLoader from "../../common/SmallLoader";
import MenuIcon from "@material-ui/icons/Menu";
import ListIcon from "@material-ui/icons/List";
import { Select } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Icon } from "@material-ui/core";

export default function ListCheckerModule({ data }) {
  const [list, setListData] = useState(false);
  const [found, setFound] = useState(false);
  const [selected, setSelected] = useState(false);

  const getListData = async () => {
    const res = await DynamicListApi.getDynamicListIndex();

    setListData(res.data);
  };

  const getData = async () => {
    getListData();
  };

  useEffect(() => {
    getData();
  }, []);

  const handleOnChange = async (value) => {
    console.log(value);

    checkIfInList(value);
  };

  const checkIfInList = async (value) => {
    const searchList = list.find((e) => e.name === value);

    const obj = {
      criteria: searchList.criteria,
      search: data.client_name,
    };

    const res = await ClientsApi.fuzzyFilterSearch("targets", obj);

    if (res.data.find((e) => e.customer_id === data.ref)) {
      setFound(true);
    } else {
      setFound(false);
      setSelected(true);
    }
  };

  if (list) {
    return (
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <h4>List Checker</h4>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-6">
            <InputLabel>List Selector</InputLabel>
            <Select fullWidth onChange={(e) => handleOnChange(e.target.value)}>
              {list.map((e, key) => (
                <MenuItem value={e.name}>{e.name} </MenuItem>
              ))}
            </Select>
          </div>
          {found && (
            <div className="form-group col-lg-6">
              <p>They're in the list</p>
              <Icon>{"tag_faces"}</Icon>
            </div>
          )}
          {!found && selected && (
            <div className="form-group col-lg-6">
              <p>They're not in the list</p>
              <Icon>{"mood_bad"}</Icon>
            </div>
          )}
        </div>
      </div>
    );
  } else return <SmallLoader />;
}
