import React from "react";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import ClientCallPopup from "../../modules/clients/ClientCallPopup";
import FilterTable from "../../common/FilterTable";
import ClientTabs from "../../common/Tabs";
import ClientRestoreModule from "../../modules/restore/ClientRestoreModule";
import UnSubbedModule from "../../modules/targets/unsubbedModule";
import { Link } from "react-router-dom";
import TargetsRestoreModule from "../../modules/restore/TargetsRestoreModule";
import InListDisplay from "../../modules/targets/InListDisplay";

export default function PageTemplateStandardIndex(props) {
  const [value, setValue] = React.useState(0);

  const tabs = [
    { icon: "person" },
    { icon: "delete" },
    { icon: "unsubscribe" },
    { icon: "search" },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let name;
  if (props.name === "targets") {
    name = props.name;
  } else {
    name = "clients";
  }
  return (
    <>
      <AppHeaderComponent
        name={name}
        theme={props.context.theme}
        history={props.history}
        context={props.context}
        addNew={() => (
          <Link to={{ pathname: `/${name}/create`, state: { type: "create" } }}>
            <AppButtonComponent
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={{
                background: props.context.theme.top_menu.button_background,
                color: props.context.theme.top_menu.button_font,
              }}
            />
          </Link>
        )}
      />
      {props.open.state && (
        <ClientCallPopup
          open={props.open.state}
          setOpen={props.setOpen}
          data={props.open.data}
        />
      )}
      <div style={{ marginTop: 180 }}>
        <ClientTabs
          tabs={tabs}
          value={value}
          setValue={setValue}
          handleChange={handleChange}
        />
        {value === 0 && (
          <FilterTable
            columns={props.columns}
            data={props.data}
            options={props.options}
          />
        )}
        {value === 1 &&
          (props.name === "targets" ? (
            <TargetsRestoreModule context={props.context} name={props.name} />
          ) : (
            <ClientRestoreModule context={props.context} name={props.name} />
          ))}

        {value === 2 && <UnSubbedModule context={props.context} />}

        {value === 3 && props.name === "targets" ? (
          <InListDisplay context={props.context} name={props.name} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
