import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import AuthContext from "./services/AuthContext";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import ClientRoutes from "./routes/clientRoutes";
import TargetRoutes from "./routes/targetRoutes";

export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route
                      path="/clients"
                      component={(route) => (
                        <ClientRoutes context={context} route={route} />
                      )}
                    />
                    <Route
                      path="/targets"
                      component={(route) => (
                        <TargetRoutes context={context} route={route} />
                      )}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
