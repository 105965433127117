import React, { useState, useEffect, useContext } from "react";
import FilterTable from "../../common/FilterTable";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { CanDelete, CanUpdate } from "../../services/Permissions";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";
import AppButton from "../../common/AppButton";
import { Icon } from "@material-ui/core";
import { ClientsApi, TargetsApi } from "@unity/components";
import Notification from "../../common/Notification";
import { RenderPageTemplate } from "../../mapping";
export default function TargetIndex({ context, route }) {
  //const context = useContext(AuthContext);
  const [data, setData] = useState(false);
  const [backup, setBackup] = useState(false);
  const [open, setOpen] = useState({ state: false });
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState("");

  //console.log(context);

  let typedText = "";

  const handleAccept = async (records) => {
    const newData = [...data];
    records.map(async (item) => {
      await deleteClient(item.id);
      newData.splice(item.data.dataIndex, 1);
    });
    setData(newData);
    setOpen({ state: false });
  };

  const handleClose = () => {
    setOpen({ state: false });
  };

  if (CanDelete("targets")) {
    select = "multiple";
  } else {
    select = "none";
  }

  const checkBackup = () => {
    var data = localStorage.getItem("cForm");
    setBackup(JSON.parse(data));
  };

  const clients = async () => {
    const object = {
      criteria: {},
      per_page: 50,
      page: 1,
    };
    // const data = await ClientsApi.filterSetPaginateIndex(object);
    // setData(data.data.data);

    //setTotal(data.data.total);

    const res = await TargetsApi.getTargets(typedText);
    console.log(res);

    setData(res.data);
    setTotal(res.total);
  };

  console.log(data);

  useEffect(() => {
    //Call silterSetPag first

    clients();
    //checkBackup();
  }, []);

  const columns = [
    { name: "company_name", label: "Name" },
    {
      name: "contact_email",
      label: "Email",
    },
    {
      name: "contact_phone",
      label: "Phone",
    },
    {
      name: "contact_mobile",
      label: "Mobile",
    },
    { name: "tag", label: "Tag" },

    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = CanUpdate("clients");

          return (
            <div>
              <Link
                to={{
                  pathname: `/targets/view/${data[dataIndex].id}`,
                  state: { client: data[dataIndex].customer_id, type: "view" },
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
              {edit && (
                <Link
                  to={{
                    pathname: `/targets/update/${data[dataIndex].id}`,
                    state: {
                      client: data[dataIndex].customer_id,
                      type: "edit",
                    },
                  }}
                >
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}
            </div>
          );
        },
        filter: false,
      },
    },
  ];

  if (CanDelete("targets")) {
    var select = "multiple";
  } else {
    var select = "none";
  }

  //console.log(data);

  const handleDelete = async (selectedRows, setSelectedRows) => {
    if (selectedRows.data.length <= 10) {
      selectedRows.data.map(async (item, key) => {
        await TargetsApi.deleteTarget(data[item.dataIndex].id);
      });
    } else {
      const resize = selectedRows.data.splice(0, 10);
      resize.map(async (item, key) => {
        await TargetsApi.deleteTarget(data[item.dataIndex].id);
      });
    }

    await clients();
    setSelectedRows([]);
  };

  const changePage = async (page) => {
    //let typeTimer = null;
    // let text = searchText;
    //clearTimeout(typeTimer);
    const object = {
      criteria: {},
      per_page: 50,
      page: page + 1,
      paginate_search: typedText,
    };

    await delay(1000);

    const data = await TargetsApi.getTargets(typedText, page + 1);
    setData(data.data);
  };

  const delay = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  };

  const options = {
    sort: false,
    responsive: "stacked",
    print: true,
    rowsPerPage: 50,
    count: total,
    download: false,
    print: false,
    filter: false,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <Icon
          onClick={() => handleDelete(selectedRows, setSelectedRows)}
          style={{ marginRight: 20, color: "grey" }}
        >
          delete
        </Icon>
      );
    },

    serverSide: true,
    onTableChange: async (action, tableState) => {
      // console.log("OnTableChange", "Action", action, "tableState", tableState);

      switch (action) {
        case "changePage":
          changePage(tableState.page);
          break;
        case "search":
          typedText = tableState.searchText;
          changePage(tableState.page);
          break;

        default:
          console.log("No Action Pressed");
      }
    },
  };

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "index");

  if (data) {
    return (
      <>
        <RenderPage
          history={history}
          context={context}
          options={options}
          data={data}
          columns={columns}
          open={open}
          setOpen={setOpen}
          name={"targets"}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
