import React from "react";
import ClientForm from "../../modules/clients/ClientForm";
import { AppHeaderComponent } from "@unity/components";

export default function PageTemplateStandardCreate(props) {
  let name;
  if (props.name === "targets") {
    name = props.name;
  } else {
    name = "clients";
  }
  return (
    <>
      <AppHeaderComponent
        name={name}
        subpage={"create"}
        saveBtn={props.saveBtn}
        theme={props.context.theme}
        history={props.history}
        context={props.context}
      />
      <div style={{ marginTop: 130 }}>
        <ClientForm {...props} />
      </div>
    </>
  );
}
