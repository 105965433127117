import React, { useEffect, useState } from "react";
import { ClientsApi } from "@unity/components";

import Loading from "../common/Loading";
import { RenderPageTemplate } from "../mapping";
import ApiLoader from "../common/ApiLoader";
import { useHistory } from "react-router-dom";

export default function ClientCreate({ context, route }) {
  const [form, setForm] = useState({ client_site: [] });
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [documents, setDocuments] = useState(false);
  const [errors, setErrors] = useState({});
  const [tab, setTab] = useState(0);
  let history = useHistory()

  const setAddressValue = (e, id) => {
    form.client_site[id][e.target.name] = e.target.value;
    setUpdate(!update);
  };

  const addAddress = () => {
    const newData = { ...form };
    newData.client_site.push({ site_contact: [] });
    setForm(newData);
  };

  const onSubmit = async () => {
    setLoading({
      status: true,
      data: "Saving your record, Please Wait....",
    });

    if (!form["client_tel"]) {
      setErrors({ client_tel: true });
      setLoading({ status: false, data: false });
    } else if (!form["client_name"]) {
      setErrors({ client_name: true });
      setLoading({ status: false, data: false });
    } else {

      await ClientsApi.saveClient(form);

      setLoading({ status: true, data: "Save Success!, Please Wait...." });
      setTimeout(() => {
        history.push("/clients/index");
        setLoading({ status: false });
      }, 3000);
    }
  };

  const onChange = (e) => {
    form[e.target.name] = e.target.value || e.target.checked;
    setUpdate(!update);
  };

  const upload = (e) => {
    setDocuments(e);
  };

  const addSiteDocs = (e, site) => {
    const data = { ...form };
    data.client_site[site]["site_docs"] = e;
    setForm(data);
  };

  const getGeoLocation = async (e, index) => {
    const postcode = e.target.value;
    const data = await getLongLat(postcode.replace(" ", ""));
    const newData = { ...form };
    if (data.status === 200) {
      newData.client_site[index].postcode = postcode;
      newData.client_site[index].site_longitude = data.result.longitude;
      newData.client_site[index].site_latitude = data.result.latitude;
      setForm(newData);
    }
  };

  const removeContact = (site, contact) => {
    const data = { ...form };
    data.client_site[site].site_contact[contact]["delete"] = 1;
    setForm(data);
  };

  const cancelRemoveSite = (site) => {
    const data = { ...form };
    delete data.client_site[site]["delete"];
    setForm(data);
  };

  const removeSite = (site) => {
    const data = { ...form };
    data.client_site[site]["delete"] = 1;
    setForm(data);
  };

  const cancelRemove = (site, contact) => {
    const data = { ...form };
    delete data.client_site[site].site_contact[contact]["delete"];
    setForm(data);
  };

  const addContact = (site) => {
    const data = { ...form };
    data.client_site[site].site_contact.push({
      site_contact_name: "",
      site_contact_tel: "",
      site_contact_extension: undefined,
      site_contact_mobile: "",
      site_contact_email: "",
      site_contact_role: "",
    });
    setForm(data);
  };

  const setContactValue = (e, index, index2) => {
    const newData = { ...form };
    newData.client_site[index].site_contact[index2][e.target.name] =
      e.target.value;
    setForm(newData);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "create");

  if (form) {
    return (
      <>
        <ApiLoader status={loading.status} data={loading.data} />

        <RenderPage
          data={form}
          type="edit"
          errors={errors}
          upload={upload}
          onChange={onChange}
          addAddress={addAddress}
          setAddressValue={setAddressValue}
          removeContact={removeContact}
          cancelRemove={cancelRemove}
          addContact={addContact}
          getGeoLocation={getGeoLocation}
          setContactValue={setContactValue}
          removeSite={removeSite}
          cancelRemoveSite={cancelRemoveSite}
          addSiteDocs={addSiteDocs}
          name={"clients"}
          subpage={"create"}
          saveBtn={onSubmit}
          setData={setForm}
          theme={context.theme}
          history={history}
          context={context}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
