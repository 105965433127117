import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import FileBase64 from "react-file-base64";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
export default function ClientStandardForm(props) {
  console.log(props);
  const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">{labels["ZZ"]}</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </select>
  );
  const [country, setCountry] = useState("GB");

  const handleFormat = (e) => {
    var val = e.target.value;
    const newData = { ...props.data };
    newData["client_tel"] = val;
    newData["calling_code"] = "+" + getCountryCallingCode(country);
    console.log(e);
    props.setData(newData);
  };

  const code = getCountryCallingCode(country);

  return (
    <>
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <h4>Client</h4>

            {props.data.file_path ? (
              <img src={props.data.file_path} className="client_logo" />
            ) : (
              <>
                {props.type !== "view" ? (
                  <FileBase64
                    multiple={false}
                    onDone={(e) => props.upload(e)}
                  />
                ) : (
                  <p>No image provided</p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-lg-4">
            <TextField
              label="Company Number"
              value={props.data.company_number ? props.data.company_number : ""}
              fullWidth={true}
              name="company_number"
              helperText={"Client Company Reg Number"}
              onChange={(e) => props.onChange(e)}
            />
          </div>

          <div className="form-group col-lg-4">
            <TextField
              label="Client Name (Company Name)"
              value={props.data.client_name ? props.data.client_name : ""}
              name="client_name"
              fullWidth={true}
              error={props.errors.client_name ? true : false}
              helperText={
                props.errors.client_name
                  ? "This field is required"
                  : "Enter the clients company name"
              }
              onChange={(e) => props.onChange(e)}
            ></TextField>
          </div>

          <div className="form-group col-lg-4">
            <TextField
              label="Status"
              value={props.data.client_status ? props.data.client_status : ""}
              name="client_status"
              fullWidth={true}
              helperText={"Accounts current standing"}
              onChange={(e) => props.onChange(e)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-4" style={{ paddingLeft: 90 }}>
            <div
              style={{
                position: "absolute",
                zIndex: 99,
                left: 0,
                top: 16,
              }}
            >
              <img
                src={`https://www.countryflags.io/${country}/shiny/32.png`}
              />
            </div>

            <div className="country-select">
              <CountrySelect
                labels={en}
                value={country}
                style={{
                  position: "absolute",
                  top: 21,
                  height: 22,
                  zIndex: 99,
                  width: 18,
                  left: 30,
                }}
                onChange={setCountry}
              />
            </div>

            <div
              style={{
                position: "absolute",
                left: 54,
                top: 18,
                fontWeight: "bold",
              }}
            >
              {"+" + code}
            </div>
            <TextField
              label="Client Telephone"
              value={props.data.client_tel ? props.data.client_tel : ""}
              fullWidth={true}
              name="client_tel"
              error={props.errors.client_tel ? true : false}
              helperText={
                props.errors.client_name
                  ? "This field is required"
                  : "Enter the clients landline"
              }
              onChange={(e) => handleFormat(e)}
            />
          </div>

          <div className="form-group col-lg-4">
            <TextField
              label="Client Primary Email"
              value={props.data.client_email ? props.data.client_email : ""}
              fullWidth={true}
              name="client_email"
              helperText={"Main company email address"}
              onChange={(e) => props.onChange(e)}
            ></TextField>
          </div>

          <div className="form-group col-lg-4">
            <TextField
              label="Website Address"
              value={props.data.client_website ? props.data.client_website : ""}
              fullWidth={true}
              name="client_website"
              helperText={"Company Website Address"}
              onChange={(e) => props.onChange(e)}
            ></TextField>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-2">
            <TextField
              label="House Number / Unit"
              value={
                props.data.client_house_number
                  ? props.data.client_house_number
                  : ""
              }
              fullWidth={true}
              name={"client_house_number"}
              helperText={"House or unit number"}
              onChange={(e) => props.onChange(e)}
            ></TextField>
          </div>
          <div className="form-group col-lg-3">
            <TextField
              label="First Line Address"
              value={
                props.data.client_first_line ? props.data.client_first_line : ""
              }
              fullWidth={true}
              name="client_first_line"
              helperText={"e.g example road"}
              onChange={(e) => props.onChange(e)}
            ></TextField>
          </div>
          <div className="form-group col-lg-3">
            <TextField
              label="Town / County"
              value={props.data.client_town ? props.data.client_town : ""}
              fullWidth={true}
              name="client_town"
              helperText={"Client town or county"}
              onChange={(e) => props.onChange(e)}
            ></TextField>
          </div>
          <div className="form-group col-lg-3">
            <TextField
              label="Postcode"
              value={
                props.data.client_postcode ? props.data.client_postcode : ""
              }
              fullWidth={true}
              name="client_postcode"
              helperText={"Client postcode"}
              onChange={(e) => props.onChange(e)}
            ></TextField>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-lg-2">
            <TextField
              id="time"
              label="Opening Time"
              type="time"
              value={props.data.client_open ? props.data.client_open : ""}
              name="client_open"
              required={true}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              fullWidth={true}
              onChange={(e) => props.onChange(e)}
            />
          </div>
          <div className="form-group col-lg-2">
            <TextField
              id="time"
              label="Closing Time"
              type="time"
              value={props.data.client_closed ? props.data.client_closed : ""}
              name="client_closed"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              fullWidth={true}
              onChange={(e) => props.onChange(e)}
            />
          </div>
          <div className="form-group col-lg-2">
            <TextField
              label="Industry"
              multiline
              value={
                props.data.client_industry ? props.data.client_industry : ""
              }
              name="client_industry"
              rowsMax={3}
              fullWidth={true}
              onChange={(e) => props.onChange(e)}
            />
          </div>
          <div className="form-group col-lg-6">
            <TextField
              id="time"
              label="Special Instructions"
              multiline
              value={props.data.client_special ? props.data.client_special : ""}
              name="client_special"
              rowsMax={3}
              fullWidth={true}
              onChange={(e) => props.onChange(e)}
            />
          </div>
        </div>
      </div>

      {props.children}
    </>
  );
}
