import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';


export default function ClientFormMarketingExtension(props) {
    return (
        <div className="container-fluid">
            <div className="form-row">
                <div className="form-group col-lg-2">
                    <TextField
                        label="Client Region"
                        value={props.data.client_region}
                        name="client_region"
                        fullWidth={true}
                        onChange={(e) => props.onChange(e)}
                    />
                </div>
                <div className="form-group col-lg-2">
                    <TextField
                        id="time"
                        label="Number of Employees"
                        value={props.data.client_number_of_employees}
                        name="client_no_of_employees"
                        type="number"
                        fullWidth={true}
                        onChange={(e) => props.onChange(e)}
                    />
                </div>
                <div className="form-group col-lg-8">
                    <TextField
                        label="Client Description"
                        multiline
                        value={props.data.customer_notes}
                        name="customer_notes"
                        rowsMax={3}
                        fullWidth={true}
                        onChange={(e) => props.onChange(e)}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-lg-2">
                    <TextField
                        label="Client Industry"
                        value={props.data.client_industry}
                        name="client_industry"
                        fullWidth={true}
                        onChange={(e) => props.onChange(e)}
                    />
                </div>
                <div className="form-group col-lg-2">
                    <TextField
                        id="time"
                        label="Client Annual Turnover"
                        value={props.data.client_annual_turnover}
                        name="client_annual_turnover"
                        type="number"
                        fullWidth={true}
                        onChange={(e) => props.onChange(e)}
                    />
                </div>
                <div className="form-group col-lg-8">
                    <TextField
                        label="Created By"
                        value={props.data.client_created_by}
                        name="client_created_by"
                        fullWidth={true}
                        onChange={(e) => props.onChange(e)}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-lg-2">
                    <TextField
                        label="Account Number"
                        value={props.data.account_number}
                        name="account_number"
                        fullWidth={true}
                        onChange={(e) => props.onChange(e)}
                    />
                </div>
                <div className="form-group col-lg-2">
                    {props.type !== "view" ? (
                        <Autocomplete
                            className="sic_autocomplete"
                            value={props.data.client_sic_id}
                            name="client_sic_id"
                            onChange={(e, val) => props.handleAutoComplete(val)}
                            options={props.sic_codes}
                            getOptionLabel={(option) =>
                                option.code + ": " + option.description
                            }
                            style={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Company Sic Code" />
                            )}
                        />
                    ) : (
                        <TextField
                            label="Sic Code"
                            value={props.data.client_description}
                            fullWidth={true}
                        />
                    )}
                </div>
            </div>

        </div>
    )
}