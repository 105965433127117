import React, { useEffect, useState } from "react";
import { ClientsApi } from "@unity/components";
import Loading from "../common/Loading";
import { RenderPageTemplate } from "../mapping";

export default function ClientView({ history, context, route }) {
  const [data, setData] = useState(false);

  const onChange = (e) => { };

  const upload = (e) => { };

  const addAddress = () => { };

  const getClient = async () => {
    const client = await ClientsApi.getClientSingle(route.match.params.id);
    setData(client.data);
  };

  useEffect(() => {
    getClient();
  }, []);
  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "view");

  if (data) {
    return (
      <>

        <RenderPage
          data={data}
          onChange={onChange}
          errors={{}}
          type="view"
          addAddress={addAddress}
          upload={upload}
          context={context}
          theme={context.theme}
          history={history}
          route={route}

        />
      </>
    );
  } else {
    return <Loading />;
  }
}
