import { Checkbox, FormControlLabel, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import { AppButtonComponent } from '@unity/components';

export default function SiteContactStandardForm(props) {
    console.log(props)

    return (
        <>
            <div>
                <div
                    style={props.contact.delete === 1 ? { opacity: "0.5" } : {}}
                >
                    <h4>Contact {props.index2 + 1}</h4>
                    {props.type !== "view" ? (
                        <div className="form-row">
                            <div className="form-group col-lg-12">
                                {props.contact.delete === 1 ? (
                                    <div
                                        onClick={() =>
                                            props.cancelRemove(props.index, props.index2)
                                        }
                                    >
                                        <AppButtonComponent
                                            text="Cancel"
                                            btnIcon="close"
                                            style={{ background: 'red', color: 'white' }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() =>
                                            props.removeContact(props.index, props.index2)
                                        }
                                    >
                                        <AppButtonComponent
                                            text="Remove Contact"
                                            btnIcon="delete"
                                            style={{ background: 'red', color: 'white' }}

                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="form-row">
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Site Contact Name"
                                value={props.contact.site_contact_name}
                                fullWidth={true}
                                name="site_contact_name"
                                helperText={"Site primary contact name"}
                                onChange={(e) =>
                                    props.setContactValue(e, props.index, props.index2)
                                }
                            />
                        </div>
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Site Contact Telephone"
                                value={props.contact.site_contact_tel}
                                fullWidth={true}
                                name="site_contact_tel"
                                helperText={"Site Contact Landline Number"}
                                onChange={(e) =>
                                    props.setContactValue(e, props.index, props.index2)
                                }
                            />
                        </div>
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Extension Number"
                                value={props.contact.site_extension_number}
                                fullWidth={true}
                                name="site_contact_extension_number"
                                helperText={"Site extension number"}
                                onChange={(e) =>
                                    props.setContactValue(e, props.index, props.index2)
                                }
                            ></TextField>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Site Contact Mobile"
                                value={props.contact.site_contact_mob}
                                fullWidth={true}
                                name="site_contact_mob"
                                helperText={"Site primary contact mobile"}
                                onChange={(e) =>
                                    props.setContactValue(e, props.index, props.index2)
                                }
                            ></TextField>
                        </div>
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Site Contact Email Address"
                                value={props.contact.site_contact_email}
                                fullWidth={true}
                                name="site_contact_email"
                                helperText={"Site primary email address"}
                                onChange={(e) =>
                                    props.setContactValue(e, props.index, props.index2)
                                }
                            ></TextField>
                        </div>
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Site Contact Role"
                                value={props.contact.site_contact_role}
                                fullWidth={true}
                                name="site_contact_role"
                                helperText={"Site contact job title / role"}
                                onChange={(e) =>
                                    props.setContactValue(e, props.index, props.index2)
                                }
                            ></TextField>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-lg-6">
                            <InputLabel>Marketing Preferences</InputLabel>
                            <FormControlLabel
                                control={<Checkbox name="phone_pref" checked={props.contact.phone_pref} onChange={(e) => props.setContactValue(e, props.index, props.index2)} />}
                                label="Phone"
                            />
                            <FormControlLabel
                                control={<Checkbox name="email_pref" checked={props.contact.email_pref} onChange={(e) => props.setContactValue(e, props.index, props.index2)} />}
                                label="Email"
                            />
                        </div>

                    </div>
                    {props.siteContactFormExtension && props.siteContactFormExtension}

                </div>
            </div>

        </>
    )

}