import React, { useContext } from "react";
import AuthContext from "./AuthContext";

//Checks the A for company module access first then R (Read Status of the permissions array sent front the config on init login)

function CanCreate(module) {
  const context = useContext(AuthContext);

  const data = context.auth.access;

  return data[module]["C"];
}

function CanRead(module) {
  const context = useContext(AuthContext);

  const data = context.auth.access;

  return data[module]["R"];
}

function CanReadAll(module) {
  const context = useContext(AuthContext);

  const data = context.auth.access;

  return data[module]["RA"];
}

function CanUpdate(module) {
  const context = useContext(AuthContext);

  const data = context.auth.access;

  return data[module]["U"];
}

function CanDelete(module) {
  const context = useContext(AuthContext);

  const data = context.auth.access;

  return data[module]["D"];
}

export { CanCreate, CanRead, CanReadAll, CanUpdate, CanDelete };
