import React from "react";
import { Checkbox, FormControlLabel, InputLabel } from "@material-ui/core";
export default function TargetExtension(props) {
  return (
    <div className="container-fluid">
      <div className="form-row">
        <div className="col-lg-12">
          <InputLabel>Sales / Marketing Channels</InputLabel>
          <FormControlLabel
            control={
              <Checkbox
                name="indirect"
                checked={props.data.indirect}
                onChange={(e) => props.onChange(e)}
              />
            }
            label="Indirect"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="direct"
                checked={props.data.direct}
                onChange={(e) => props.onChange(e)}
              />
            }
            label="Direct"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="online"
                checked={props.data.online}
                onChange={(e) => props.onChange(e)}
              />
            }
            label="Online"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="corporate"
                checked={props.data.corporate}
                onChange={(e) => props.onChange(e)}
              />
            }
            label="Corporate"
          />
        </div>
      </div>
    </div>
  );
}
