import React, { useEffect, useState } from "react";
import SmallLoader from "../../common/SmallLoader";
import { AppButtonComponent } from "@unity/components";
import FilterTable from "../../common/FilterTable";
import { ClientsApi, TargetsApi } from "@unity/components";

export default function TargetsRestoreModule(props) {
  const [data, setData] = useState(false);

  console.log(props);

  const getData = async () => {
    const res = await TargetsApi.getRestoreTargets();
    setData(res.data);
    console.log(res);
  };

  const handleRestore = async (id) => {
    const res = await TargetsApi.restoreTarget(id);
    console.log(res);
    await getData();
  };

  var select = "multiple";

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    selectableRows: select,
    rowsPerPage: 10,
    page: 0,
  };

  const columns = [
    {
      name: "",
      label: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].logo_path ? (
            <div
              style={{
                backgroundImage: "url(" + data.logo_path + ")",
                backgroundPosition: "center, center",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                height: 50,
                width: 50,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#d2d2d2",
                borderRadius: "100%",
                display: "inline-block",
              }}
            />
          ) : (
            <div
              style={{
                backgroundImage:
                  "url(" +
                  "https://unity.world/wp-content/uploads/brizy/9/assets/images/iW=167&iH=44&oX=1&oY=0&cW=164&cH=44/Unity-Group.png" +
                  ")",
                backgroundPosition: "center, center",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                height: 50,
                width: 50,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#d2d2d2",
                borderRadius: "100%",
                display: "inline-block",
              }}
            />
          );
        },
      },
    },
    { name: "company_name", label: "Name" },
    { name: "contact_email", label: "Email" },
    { name: "contact_phone", label: "Phone" },
    { name: "contact_mobile", label: "Mobile" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = true;

          return (
            <div>
              <AppButtonComponent
                text="restore"
                btnIcon="check"
                style={{
                  background: "green",
                  color: "white",
                  marginLeft: 10,
                }}
                onClick={() => handleRestore(data[dataIndex].id)}
              />
            </div>
          );
        },
        filter: false,
      },
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <FilterTable columns={columns} data={data} options={options} />
      </>
    );
  } else {
    return <SmallLoader />;
  }
}
