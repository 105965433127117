import React, { useEffect, useState } from "react";
import { ClientsApi } from "@unity/components";
import SmallLoader from "../../common/SmallLoader";
import MenuIcon from "@material-ui/icons/Menu";
import ListIcon from "@material-ui/icons/List";

export default function ClientHistoryModule(props) {
  const [data, setData] = useState(false);
  const [active, setActive] = useState(false);

  const getData = async () => {
    const paths = [1, 2, 3, 4, 5];

    const promises = paths.map(async (type) => {
      try {
        return ClientsApi.getClientLogs(type, props.id);
      } catch (e) {
        return e;
      }
    });

    const res = await Promise.all(promises);

    setData(res);
  };

  const keys_to_name = {
    0: "Customer Logs",
    1: "Site Logs",
    2: "Contact Logs",
    3: "Customer Documents Logs",
    4: "Site Document Logs",
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(data)

  if (data) {
    return (
      <>
        {data.map((item, key) => {
          return (
            <>
              <div
                className="action-bar"
                onClick={() => setActive(active === key ? false : key)}
              >
                {keys_to_name[key]}
                <div style={{ float: "right" }}>
                  {active === key ? <ListIcon /> : <MenuIcon />}
                </div>
              </div>
              <div
                style={
                  active === key ? { display: "block" } : { display: "none" }
                }
              >
                {item.data && item.data.map((log) => {
                  return (
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                      className="history-row"
                    >
                      <div style={{ flex: 1 }}>
                        {log.user ? log.user : "User not found"}
                      </div>
                      <div style={{ flex: 1 }}>
                        {log.subject} - {log.description}
                      </div>
                      <div style={{ flex: 1 }}>{log.created_at}</div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </>
    );
  } else {
    return <SmallLoader />;
  }
}
