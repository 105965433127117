import React from "react";
import Clients from "../pages/index";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ClientView from "../pages/view";
import ClientEdit from "../pages/edit";
import AuthContext from "../services/AuthContext";
import ClientCreate from "../pages/create";
import { ClientsApi } from "@unity/components";
export default function ClientRoutes({ context, history, route }) {
  //let history = useHistory();
  //let context = { ...props };
  // console.log("Props", props);
  return (
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup className="transition-group">
            <CSSTransition key={location.key} timeout={300} classNames="fade">
              <section className="route-section">
                <Switch location={location}>
                  <Route path="/location" exact component={Location} />

                  {context.auth.access.clients["A"] &&
                    context.auth.access.clients["R"] && (
                      <Route
                        path="/clients/index"
                        exact
                        component={(route) => (
                          <Clients
                            ClientsApi={ClientsApi}
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}

                  {context.auth.access.clients["A"] &&
                    context.auth.access.clients["R"] && (
                      <Route
                        path="/clients/view/:id"
                        exact
                        component={(route) => (
                          <ClientView
                            ClientsApi={ClientsApi}
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}
                  {context.auth.access.clients["A"] &&
                    context.auth.access.clients["U"] && (
                      <Route
                        path="/clients/edit/:id"
                        exact
                        component={(route) => (
                          <ClientEdit
                            ClientsApi={ClientsApi}
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}
                  {context.auth.access.clients["A"] &&
                    context.auth.access.clients["C"] && (
                      <Route
                        path="/clients/create"
                        exact
                        component={(route) => (
                          <ClientCreate
                            ClientsApi={ClientsApi}
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}
                </Switch>
              </section>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  );
}
