import React, { useContext } from "react";
import { RenderComponent } from '../../mapping';
import AuthContext from "../../services/AuthContext";

export default function ClientForm(props) {

  const context = useContext(AuthContext);

  const FormComponent = RenderComponent(context.auth.app_uuid);


  return (
    <div>
      <FormComponent {...props} />
    </div>
  );
}
