import React, { useContext, useEffect, useState } from "react";
import { ClientsApi } from "@unity/components";
import PhoneInput, {
  formatPhoneNumberIntl,
} from "react-phone-number-input";

import Loading from "../common/Loading";
import { RenderPageTemplate } from "../mapping";
import ApiLoader from "../common/ApiLoader";
export default function ClientEdit({ history, context, route }) {

  const [form, setForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [documents, setDocuments] = useState({});
  const [errors, setErrors] = useState({});
  const [tab, setTab] = useState(0);
  const getClient = async () => {
    const client = await ClientsApi.getClientSingle(route.match.params.id);
    setForm(client.data);
  };

  const setAddressValue = (e, id) => {
    form.client_site[id][e.target.name] = e.target.value;
    setUpdate(!update);
  };

  const addAddress = () => {
    const newData = { ...form };
    newData.client_site.push({ site_contact: [] });
    setForm(newData);
  };

  const onSubmit = async () => {
    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });

    if (!form["client_tel"]) {
      setErrors({ client_tel: true });
      setLoading({ status: false, data: false });
    } else {

      form["file_path"] = documents;

      const newForm = { ...form };

      newForm["client_tel"] = formatPhoneNumberIntl("+" + form["client_tel"]);

      const res = await ClientsApi.updateClient(form);
      console.log(res)
      setLoading({ status: true, data: "Save Success!, Please Wait...." });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    }
  };

  const onChange = (e) => {
    if (e.target.name === "company_number") {
      const newForm = { ...form };
      newForm[e.target.name] = e.target.value;
      setForm(newForm);
    } else {
      const newForm = { ...form };
      newForm[e.target.name] = e.target.value
        ? e.target.value
        : e.target.checked;
      setForm(newForm);
    }
  };

  const upload = (e) => {
    setDocuments(e);
  };

  const addSiteDocs = (e, site) => {
    const data = { ...form };
    data.client_site[site]["site_docs"] = e;
    setForm(data);
  };

  const getGeoLocation = async (e, index) => {
    const postcode = e.target.value;
    console.log(postcode);
    const data = await ClientsApi.getLongLat(postcode.replace(" ", ""));
    const newData = { ...form };
    newData.client_site[index].site_postcode = postcode;
    if (data.status === 200) {
      newData.client_site[index].site_longitude = data.result.longitude;
      newData.client_site[index].site_latitude = data.result.latitude;
    }
    console.log("new data", newData);

    setForm(newData);
  };

  const removeContact = (site, contact) => {
    const data = { ...form };
    data.client_site[site].site_contact[contact]["delete"] = 1;
    setForm(data);
  };

  const cancelRemoveSite = (site) => {
    const data = { ...form };
    delete data.client_site[site]["delete"];
    setForm(data);
  };

  const removeSite = (site) => {
    const data = { ...form };
    data.client_site[site]["delete"] = 1;
    setForm(data);
  };

  const cancelRemove = (site, contact) => {
    const data = { ...form };
    delete data.client_site[site].site_contact[contact]["delete"];
    setForm(data);
  };

  const addContact = (site) => {
    const data = { ...form };
    data.client_site[site].site_contact.push({
      site_contact_name: "",
      site_contact_tel: "",
      site_contact_extension: undefined,
      site_contact_mobile: "",
      site_contact_email: "",
      site_contact_role: "",
    });
    setForm(data);
  };

  const setContactValue = (e, index, index2) => {
    const newData = { ...form };
    newData.client_site[index].site_contact[index2][e.target.name] =
      e.target.value;
    setForm(newData);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    getClient();
  }, []);

  console.log(form);

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "edit");

  if (form) {
    return (
      <>
        <ApiLoader status={loading.status} data={loading.data} />
        <RenderPage
          data={form}
          setData={setForm}
          route={route}
          type="edit"
          errors={errors}
          upload={upload}
          onChange={onChange}
          addAddress={addAddress}
          setAddressValue={setAddressValue}
          removeContact={removeContact}
          cancelRemove={cancelRemove}
          addContact={addContact}
          getGeoLocation={getGeoLocation}
          setContactValue={setContactValue}
          removeSite={removeSite}
          cancelRemoveSite={cancelRemoveSite}
          addSiteDocs={addSiteDocs}
          theme={context.theme}
          context={context}
          onSubmit={onSubmit}
          history={history}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
