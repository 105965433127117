import React from "react";
import ClientForm from "../../modules/clients/ClientForm";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { Link } from "react-router-dom";
import ClientTabs from "../../common/Tabs";
import ClientHistoryModule from "../../modules/history/ClientHistoryModule";
import CompaniesHouseModule from "../../modules/companies-house/CompaniesHouseModule";
import ListCheckerModule from "../../modules/targets/ListCheckerModule";
export default function PageTemplateStandardEdit(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(props);

  let name;
  if (props.name === "targets") {
    name = props.name;
  } else {
    name = "clients";
  }

  const tabs = [
    { icon: "person" },
    { icon: "history" },
    { icon: "home" },
    { icon: "search" },
  ];
  return (
    <>
      <AppHeaderComponent
        name={name}
        subpage={props.data.client_name}
        subpage2="edit"
        saveBtn={props.onSubmit}
        theme={props.context.theme}
        context={props.context}
        back={() => props.history.goBack()}
        addNew={() => (
          <Link to={`/${name}/create`}>
            <AppButtonComponent
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={{
                background: props.context.theme.top_menu.button_background,
                color: props.context.theme.top_menu.button_font,
              }}
            />
          </Link>
        )}
      />
      <div style={{ marginTop: 180 }}>
        <ClientTabs
          tabs={tabs}
          value={value}
          setValue={setValue}
          handleChange={handleChange}
        />
        {value === 0 && <ClientForm {...props} />}

        {value === 1 && (
          <ClientHistoryModule
            {...props}
            id={props.route.match.params.id}
            context={props.context}
          />
        )}

        {value === 2 && (
          <CompaniesHouseModule
            context={props.context}
            data={props.data}
            setData={props.setData}
            reference={props.data.ref}
          />
        )}
        {value === 3 && (
          <ListCheckerModule
            context={props.context}
            data={props.data}
            setData={props.setData}
          />
        )}
      </div>
    </>
  );
}
