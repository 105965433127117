import React from "react";
import ClientForm from "../../modules/clients/ClientForm";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { Link } from "react-router-dom";
import ClientTabs from "../../common/Tabs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ClientHistoryModule from "../../modules/history/ClientHistoryModule";
import CompaniesHouseModule from "../../modules/companies-house/CompaniesHouseModule";
import ListCheckerModule from "../../modules/targets/ListCheckerModule";
export default function PageTemplateStandardView(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { icon: "person" },
    { icon: "history" },
    { icon: "home" },
    { icon: "search" },
  ];
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  let name;
  if (props.name === "targets") {
    name = props.name;
  } else {
    name = "clients";
  }

  return (
    <>
      <AppHeaderComponent
        name={name}
        context={props.context}
        subpage="view"
        subpage2={props.data.client_name}
        history={props.history}
        theme={props.context.theme}
        addNew={() => (
          <Link to={`/${name}/create`}>
            <AppButtonComponent
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={{
                background: props.context.theme.top_menu.button_background,
                color: props.context.theme.top_menu.button_font,
              }}
            />
          </Link>
        )}
        editItem={() => (
          <Link to={`/clients/edit/${props.route.match.params.id}`}>
            <AppButtonComponent
              text="Edit"
              btnIcon="edit"
              stylename="primary"
              style={{
                background: props.context.theme.top_menu.button_background,
                color: props.context.theme.top_menu.button_font,
              }}
            />
          </Link>
        )}
      />

      <div style={{ marginTop: 180 }}>
        <ClientTabs
          tabs={tabs}
          value={value}
          setValue={setValue}
          handleChange={handleChange}
        />
        {value === 0 && <ClientForm {...props} />}

        {value === 1 && (
          <ClientHistoryModule
            {...props}
            id={props.route.match.params.id}
            context={props.context}
          />
        )}

        {value === 2 && (
          <CompaniesHouseModule
            context={props.context}
            data={props.data}
            setData={props.setData}
          />
        )}

        {value === 3 && (
          <ListCheckerModule
            context={props.context}
            data={props.data}
            setData={props.setData}
          />
        )}
      </div>
    </>
  );
}
