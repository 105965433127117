import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
export default function ClientCallPopup({
    open, setOpen, data
}) {
    const [call, setCall] = useState(data.contacts.length > 0 ? data.contacts[0] : false);


    const handleClose = () => {
        setOpen(false);
    };

    const handleCall = () => {
        const el = document.getElementById("dialClick");
        el.click()
        setOpen({ state: false })
    }

    const handleSelect = (id) => {
        const el = data.contacts.find((e) => e.id === id);
        setCall(el);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <div style={{ textAlign: 'center', padding: 24, minWidth: 350 }}>
                    {data.logo_path ?
                        <div
                            style={{
                                backgroundImage:
                                    "url(" +
                                    data.logo_path +
                                    ")",
                                backgroundPosition: "center, center",
                                backgroundSize: "100%",
                                backgroundRepeat: "no-repeat",
                                height: 50,
                                width: 50,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#d2d2d2",
                                borderRadius: "100%",
                                display: 'inline-block'
                            }}
                        />
                        :
                        <div
                            style={{
                                backgroundImage:
                                    "url(" +
                                    "https://unity.world/wp-content/uploads/brizy/9/assets/images/iW=167&iH=44&oX=1&oY=0&cW=164&cH=44/Unity-Group.png" +
                                    ")",
                                backgroundPosition: "center, center",
                                backgroundSize: "100%",
                                backgroundRepeat: "no-repeat",
                                height: 50,
                                width: 50,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#d2d2d2",
                                borderRadius: "100%",
                                display: 'inline-block'
                            }}
                        />
                    }
                    <p style={{ marginBottom: 0 }}><b>
                        {data.name}</b></p>

                </div>
                <DialogContent>
                    <a id="dialClick" href={`dial:${call.tel}`}></a>
                    {call ?
                        <Select
                            margin="dense"
                            fullWidth
                            value={call.id}
                            onChange={(e) => handleSelect(e.target.value)}
                        >
                            {data.contacts.length > 0 && data.contacts.map((item, key) => {
                                return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                            })}
                        </Select>
                        :
                        <p style={{ textAlign: 'center' }}>This client has no contacts</p>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
            </Button>
                    <Button onClick={handleCall} color="secondary">
                        Call
            </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}