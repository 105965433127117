import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  TextField,
} from "@material-ui/core";
import TargetMiscTable from "../tables/targets/TargetMiscTable";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import "../forms/form.css";
export default function TargetStandardForm({
  data,
  handleChange,
  handleMiscChange,
  handleMiscAdd,
  handleMiscDelete,
  type,
  setData,
}) {
  const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">{labels["ZZ"]}</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </select>
  );
  const [country, setCountry] = useState("GB");

  const handleFormat = (e) => {
    const val = e.target.value;
    const newData = { ...data };
    newData[e.target.name] = val;
    newData[e.target.name + "_cc"] = "+" + getCountryCallingCode(country);

    setData(newData);
  };

  const code = getCountryCallingCode(country);

  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h4>Target Info</h4>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Company Name"
                name="company_name"
                value={data.company_name ? data.company_name : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Sic Code"
                name="sic_code"
                value={data.sic_code ? data.sic_code : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Sic Code Description"
                name="sic_code_description"
                value={
                  data.sic_code_description ? data.sic_code_description : ""
                }
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Industry"
                name="industry"
                value={data.industry ? data.industry : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Number of Employees"
                name="number_of_employees"
                value={data.number_of_employees ? data.number_of_employees : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Website"
                name="website"
                value={data.website ? data.website : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Tag"
                name="tag"
                value={data.tag ? data.tag : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-6">
              <InputLabel>Marketing Preferences</InputLabel>
              <FormControlLabel
                disabled={type === "view"}
                control={
                  <Checkbox
                    name="phone_pref"
                    checked={data.phone_pref}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.checked)
                    }
                  />
                }
                label="Phone"
              />
              <FormControlLabel
                disabled={type === "view"}
                control={
                  <Checkbox
                    name="email_pref"
                    checked={data.email_pref}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.checked)
                    }
                  />
                }
                label="Email"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h4>Target Address</h4>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Address Line 1"
                name="address_line_1"
                value={data.address_line_1 ? data.address_line_1 : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Address Line 2"
                name="address_line_2"
                value={data.address_line_2 ? data.address_line_2 : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Address Line 3"
                name="address_line_3"
                value={data.address_line_3 ? data.address_line_3 : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Town"
                name="town"
                value={data.town ? data.town : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="County"
                name="county"
                value={data.county ? data.county : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Postcode"
                name="postcode"
                value={data.postcode ? data.postcode : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Region"
                name="region"
                value={data.region ? data.region : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h4>Contact Details</h4>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Title"
                name="contact_title"
                value={data.contact_title ? data.contact_title : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Name"
                name="contact_name"
                value={data.contact_name ? data.contact_name : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Role"
                name="contact_role"
                value={data.contact_role ? data.contact_role : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-4" style={{ paddingLeft: 90 }}>
              <div
                style={{
                  position: "absolute",
                  zIndex: 99,
                  left: 0,
                  top: 16,
                }}
              >
                {/*                 <img
                  src={`https://www.countryflags.io/${country}/shiny/32.png`}
                /> */}
              </div>

              <div className="country-select">
                <CountrySelect
                  labels={en}
                  value={country}
                  style={{
                    position: "absolute",
                    top: 21,
                    height: 22,
                    zIndex: 99,
                    width: 18,
                    left: 30,
                  }}
                  onChange={setCountry}
                />
              </div>

              <div
                style={{
                  position: "absolute",
                  left: 54,
                  top: 18,
                  fontWeight: "bold",
                  paddingLeft: 5,
                }}
              >
                {"+" + code}
              </div>
              <TextField
                disabled={type === "view"}
                label="Phone"
                name="contact_phone"
                value={data.contact_phone ? data.contact_phone : ""}
                onChange={(e) => handleFormat(e)}
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-4" style={{ paddingLeft: 90 }}>
              <div
                style={{
                  position: "absolute",
                  zIndex: 99,
                  left: 0,
                  top: 16,
                }}
              >
                {/*                 <img
                  src={`https://www.countryflags.io/${country}/shiny/32.png`}
                /> */}
              </div>

              <div className="country-select">
                <CountrySelect
                  labels={en}
                  value={country}
                  style={{
                    position: "absolute",
                    top: 21,
                    height: 22,
                    zIndex: 99,
                    width: 18,
                    left: 30,
                  }}
                  onChange={setCountry}
                />
              </div>

              <div
                style={{
                  position: "absolute",
                  left: 54,
                  top: 18,
                  fontWeight: "bold",
                  paddingLeft: 5,
                }}
              >
                {"+" + code}
              </div>
              <TextField
                disabled={type === "view"}
                label="Mobile"
                name="contact_mobile"
                value={data.contact_mobile ? data.contact_mobile : ""}
                onChange={(e) => handleFormat(e)}
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-4">
              <TextField
                disabled={type === "view"}
                label="Email"
                name="contact_email"
                value={data.contact_email ? data.contact_email : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h4>Misc</h4>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-12">
              <TargetMiscTable
                data={data}
                handleMiscChange={handleMiscChange}
                handleMiscAdd={handleMiscAdd}
                handleMiscDelete={handleMiscDelete}
                type={type}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h4>Notes</h4>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                disabled={type === "view"}
                label="Notes"
                name="notes"
                multiline
                value={data.notes ? data.notes : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
