import React from "react";
import ClientStandardForm from "../forms/ClientStandardForm";
import TargetExtension from "../form-extensions/TargetExtension";
import StandardSiteForm from "../forms/SiteStandardForm";
import SiteContactStandardForm from "../forms/SiteContactStandardForm";

export default function ClientTargetFormLayout(props) {
  return (
    <>
      <div className="block">
        <ClientStandardForm {...props} />
        <TargetExtension {...props} />
      </div>

      <StandardSiteForm
        {...props}
        siteFormExtension={false}
        siteContactStandardForm={<SiteContactStandardForm />}
        siteContactFormExtension={false}
      />
    </>
  );
}
