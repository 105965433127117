import React, { useEffect, useState } from "react";
import { DynamicListApi, ClientsApi, ActivityApi } from "@unity/components";
import SmallLoader from "../../common/SmallLoader";
import MenuIcon from "@material-ui/icons/Menu";
import ListIcon from "@material-ui/icons/List";
import { Select } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Icon } from "@material-ui/core";
import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";

import FilterTable from "../../common/FilterTable";

export default function UnSubbedModule() {
  const [emailGroups, setEmailGroups] = useState(false);
  const [emails, setEmails] = useState([{}]);

  const getEmailGroups = async () => {
    const res = await ActivityApi.getUnsubGroups();

    console.log(res);
    setEmailGroups(res.data);
  };

  const handleAccordian = async (id) => {
    console.log(id);

    const res = await ActivityApi.getUnsubGroupsEmails(id);

    setEmails(res.data);

    console.log(res.data);
  };

  const columns = [{ name: "email", label: "Email" }];

  const options = {};

  const getData = async () => {
    getEmailGroups();
  };
  useEffect(() => {
    getData();
  }, []);

  if (emailGroups) {
    return (
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <h4>Unsub Checker</h4>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-12">
            {emailGroups.map((item, key) => (
              <Accordion onChange={(e) => handleAccordian(item.id)}>
                <AccordionSummary>
                  <b>{item.name}</b> - <i>{item.description}</i>
                </AccordionSummary>
                <FilterTable
                  columns={columns}
                  data={emails}
                  options={options}
                />
                <AccordionDetails></AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    );
  } else return <p>Loading</p>;
}
