import React from "react";
import ClientStandardFormLayout from "./form-layouts/ClientStandardFormLayout";
import ClientFormMarketingExtension from "./form-layouts/ClientMarketingFormLayout";

import PageTemplateStandardCreate from "./page-templates/standard/create";
import PageTemplateStandardView from "./page-templates/standard/view";
import PageTemplateStandardEdit from "./page-templates/standard/edit";
import PageTemplateStandardIndex from "./page-templates/standard";

//Form extension for targets on clients form
import TargetExtension from "./form-extensions/TargetExtension";
import TargetsPageStandardEdit from "./page-templates/targets/edit";
import ClientTargetFormLayout from "./form-layouts/ClientTargetFormLayout";

const form_mapping = {
  "76635ee3-bb89-4ff4-86aa-b4dfae0887b4": ClientFormMarketingExtension,
  //Add custom form layouts here

  //Form extension for targets when in clients
  "ff0eaf73-93b8-44f0-8219-22cacda89850": ClientTargetFormLayout,
};

const RenderComponent = (uuid) => {
  if (form_mapping[uuid]) return form_mapping[uuid];
  return ClientStandardFormLayout;
};

const page_mapping = {
  default: {
    view: PageTemplateStandardView,
    edit: PageTemplateStandardEdit,
    create: PageTemplateStandardCreate,
    index: PageTemplateStandardIndex,
  },
  "76635ee3-bb89-4ff4-86aa-b4dfae0887b4": PageTemplateStandardCreate,
  //Add custom form layouts here

  //Custom Page for when creating a target when in the clients inside the marketing system
  "ff0eaf73-93b8-44f0-8219-22cacda89850": PageTemplateStandardCreate,
};

const RenderPageTemplate = (uuid, type) => {
  if (page_mapping[uuid]) {
    if (page_mapping[uuid][type]) {
      return page_mapping[uuid][type];
    } else {
      return page_mapping["default"][type];
    }
  } else {
    return page_mapping["default"][type];
  }
};

export { RenderComponent, RenderPageTemplate };
