import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import TargetCreate from "../pages/targets/create";
import TargetEdit from "../pages/targets/edit";
import TargetIndex from "../pages/targets/index";
import TargetView from "../pages/targets/view";
export default function TargetRoutes({ context, history, route }) {
  //let history = useHistory();
  //let context = { ...props };
  // console.log("Props", props);
  return (
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup className="transition-group">
            <CSSTransition key={location.key} timeout={300} classNames="fade">
              <section className="route-section">
                <Switch location={location}>
                  <Route path="/location" exact component={Location} />

                  {context.auth.access.clients["A"] &&
                    context.auth.access.clients["R"] && (
                      <Route
                        path="/targets/index"
                        exact
                        component={(route) => (
                          <TargetIndex
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}

                  {context.auth.access.clients["A"] &&
                    context.auth.access.clients["R"] && (
                      <Route
                        path="/targets/view/:id"
                        exact
                        component={(route) => (
                          <TargetView
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}

                  {context.auth.access.clients["A"] &&
                    context.auth.access.clients["R"] && (
                      <Route
                        path="/targets/create"
                        exact
                        component={(route) => (
                          <TargetCreate
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}

                  {context.auth.access.clients["A"] &&
                    context.auth.access.clients["R"] && (
                      <Route
                        path="/targets/update/:id"
                        exact
                        component={(route) => (
                          <TargetCreate
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}
                </Switch>
              </section>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  );
}
