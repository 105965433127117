import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  TextField,
} from "@material-ui/core";
import React, { useContext } from "react";
import { AppButtonComponent } from "@unity/components";
import FileBase64 from "react-file-base64";
import SiteContactStandardForm from './SiteContactStandardForm';
import AuthContext from "../services/AuthContext";

export default function StandardSiteForm(props) {
  console.log(props.context.theme.sidebar.background);
  return (
    <>
      {props.data.client_site &&
        props.data.client_site.map((address, index) => {
          return (
            <div className="block">
              <h4
                style={
                  address.delete && address.delete === 1
                    ? { opacity: "0.5" }
                    : {}
                }
              >
                Site {index + 1}
              </h4>
              {props.type !== "view" ? (
                <div className="form-row">
                  <div className="form-group col-lg-12">
                    {address.delete && address.delete === 1 ? (
                      <div onClick={() => props.cancelRemoveSite(index)}>
                        <AppButtonComponent
                          text="Cancel"
                          btnIcon="close"
                          style={{ background: "red", color: "white" }}
                        />
                      </div>
                    ) : (
                      <div onClick={() => props.removeSite(index)}>
                        <AppButtonComponent
                          text="Remove Site"
                          btnIcon="delete"
                          style={{ background: "red", color: "white" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                style={
                  address.delete && address.delete === 1
                    ? { opacity: "0.5" }
                    : {}
                }
              >
                <div className="form-row">
                  <div className="form-group col-lg-2">
                    <TextField
                      label="House Number / Unit"
                      value={address.site_house_number}
                      fullWidth={true}
                      name={"site_house_number"}
                      helperText={"House or unit number"}
                      onChange={(e) => props.setAddressValue(e, index)}
                    ></TextField>
                  </div>
                  <div className="form-group col-lg-3">
                    <TextField
                      label="First Line Address"
                      value={address.site_first_line}
                      fullWidth={true}
                      name="site_first_line"
                      helperText={"e.g example road"}
                      onChange={(e) => props.setAddressValue(e, index)}
                    ></TextField>
                  </div>
                  <div className="form-group col-lg-3">
                    <TextField
                      label="Town / County"
                      value={address.site_town}
                      fullWidth={true}
                      name="site_town"
                      helperText={"Client town or county"}
                      onChange={(e) => props.setAddressValue(e, index)}
                    ></TextField>
                  </div>
                  <div className="form-group col-lg-3">
                    <TextField
                      label="Postcode"
                      defaultValue={address.site_postcode}
                      fullWidth={true}
                      name="site_postcode"
                      helperText={"Client postcode"}
                      onChange={(e) => props.getGeoLocation(e, index)}
                    ></TextField>
                  </div>
                  <div className="form-group col-lg-3">
                    <TextField
                      label="Longitude"
                      value={address.site_long}
                      fullWidth={true}
                      name="site_long"
                      helperText={"Longitude for app to detect on site arrival"}
                      onChange={(e) => props.setAddressValue(e, index)}
                    ></TextField>
                  </div>
                  <div className="form-group col-lg-3">
                    <TextField
                      label="Latitude"
                      value={address.site_lat}
                      fullWidth={true}
                      name="site_lat"
                      helperText={"Latitude for app to detect on site arrival"}
                      onChange={(e) => props.setAddressValue(e, index)}
                    ></TextField>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-2">
                    <TextField
                      id="time"
                      label="Opening Time"
                      type="time"
                      value={address.site_open}
                      name="site_open"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      fullWidth={true}
                      onChange={(e) => props.setAddressValue(e, index)}
                    />
                  </div>
                  <div className="form-group col-lg-2">
                    <TextField
                      id="time"
                      label="Closing Time"
                      type="time"
                      value={address.site_closed}
                      name="site_closed"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      fullWidth={true}
                      onChange={(e) => props.setAddressValue(e, index)}
                    />
                  </div>
                  <div className="form-group col-lg-8">
                    <TextField
                      id="time"
                      label="Special Instructions"
                      multiline
                      value={address.site_special}
                      name="site_special"
                      rowsMax={3}
                      fullWidth={true}
                      onChange={(e) => props.setAddressValue(e, index)}
                    />
                  </div>
                </div>
                {props.siteFormExtension && props.siteFormExtension}
                {address.site_contact &&
                  address.site_contact.map((contact, index2) => {
                    return (
                      <SiteContactStandardForm
                        contact={contact}
                        {...props}
                        index={index}
                        index2={index2}
                      />
                    );
                  })}

                {props.type !== "view" ? (
                  <div className="form-row">
                    <div className="form-group col-lg-12">
                      <div onClick={() => props.addContact(index)}>
                        <AppButtonComponent
                          text="Add Contact"
                          btnIcon="add"
                          style={{
                            background: props.context.theme.sidebar.background,
                            color: "white",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div
                style={
                  address.delete && address.delete === 1
                    ? { opacity: "0.5" }
                    : {}
                }
              >
                <h4>Site Documents</h4>
                {props.type !== "view" && (
                  <FileBase64
                    multiple={true}
                    onDone={(e) => props.addSiteDocs(e, index)}
                  />
                )}
              </div>
            </div>
          );
        })}

      {props.type !== "view" ? (
        <div className="form-row">
          <div className="form-group col-lg-12">
            <div onClick={() => props.addAddress()}>
              <AppButtonComponent
                text="Add Site"
                btnIcon="add"
                style={{
                  background: props.context.theme.sidebar.background,
                  color: "white",
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
