import React, { useEffect, useState } from "react";
import { TargetsApi, AppHeaderComponent } from "@unity/components";
import Loading from "../../common/Loading";
import { RenderPageTemplate } from "../../mapping";

import TargetStandardForm from "../../forms/TargetStandardForm";

export default function TargetView(props) {
  const [data, setData] = useState(false);
  const [tab, setTab] = useState(0);

  const getTarget = async () => {
    const res = await TargetsApi.getSingleTarget(props.route.match.params.id);
    setData(res.data);
  };

  useEffect(() => {
    getTarget();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          subpage="view"
          theme={props.context.theme}
          context={props.context}
          name={"targets"}
        />
        <div style={{ paddingTop: "120px" }}>
          <TargetStandardForm data={data} type={"view"} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
