import React from 'react';
import ClientStandardForm from '../forms/ClientStandardForm';
import SiteContactStandardForm from '../forms/SiteContactStandardForm';
import StandardSiteForm from '../forms/SiteStandardForm';


const ClientStandardFormLayout = (props) => {

    return (
        <>
            <div className="block">
                <ClientStandardForm {...props} />
            </div>
            <StandardSiteForm {...props}
                siteFormExtension={false}
                siteContactStandardForm={<SiteContactStandardForm />}
                siteContactFormExtension={false}

            />

        </>
    )
}

export default ClientStandardFormLayout;